import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import fontawesome_klhsrycjcK from "/vercel/path0/plugins/fontawesome.js";
import gtm_client_KEcT5dOoKs from "/vercel/path0/plugins/gtm.client.js";
import phoneRaw_RjB5rEIQAP from "/vercel/path0/plugins/phoneRaw.js";
import recaptcha_client_ePVGy12Odm from "/vercel/path0/plugins/recaptcha.client.js";
import sentry_client_GoGQuZo4Et from "/vercel/path0/plugins/sentry.client.js";
import slideupdown_YSfnQtNV8K from "/vercel/path0/plugins/slideupdown.js";
import v_click_outside_tGcsVo1qCA from "/vercel/path0/plugins/v-click-outside.js";
import v_parse_links_vxsO12A3tg from "/vercel/path0/plugins/v-parse-links.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  fontawesome_klhsrycjcK,
  gtm_client_KEcT5dOoKs,
  phoneRaw_RjB5rEIQAP,
  recaptcha_client_ePVGy12Odm,
  sentry_client_GoGQuZo4Et,
  slideupdown_YSfnQtNV8K,
  v_click_outside_tGcsVo1qCA,
  v_parse_links_vxsO12A3tg
]